<script>
import { jsPDF } from 'jspdf'
import axios from 'axios';

import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';

//import CryptoJS from 'vue-cryptojs'

//import Vue from "vue";
//import { DialogPlugin } from '@syncfusion/ej2-vue-popups';

export default {
  name: 'Academy-Of-Akara-Numerology',
  props: {
  },
  
  data() {
    return {
        name:               `${this.value ? this.value : ''}`,
        day:                `${this.value ? new Date(this.value).getDate() : ''}`,
        month:              `${this.value ? new Date(this.value).getMonth() + 1 : ''}`,
        year:               `${this.value ? new Date(this.value).getFullYear(): ''}`,
        email:              `${this.value ? this.value : ''}`,
        previewImage:       '',
        cropperWidth:       '250px',
        cropperHeight:      '250px',
        cropperVisible:     'hidden',
        cropperTop:         '0px',
        croppedImage:       null,
        croppedImageLoaded: false,
        target:             '#targetdiv',
        width:              '340px',
        content:            'This is tha content.',
        headerImage:        'src/assets/images/Headergeheel.png',
        greyPart:           'src/assets/images/balk-grijsV1.png',
        underHeaderLine:    'src/assets/images/Header-onderbalkV1.png',
        underHeaderLeft:    'src/assets/images/BluePrintAnalysisLeftPart.png',
        previewBlueprint:   'src/assets/images/Preview-Blueprint-V2.png',
        inputBlue:          'src/assets/images/invoerveld-blauw.png',
        inputWhite:         'src/assets/images/invoerveld-wit.png',
        buttonYellow:       'src/assets/images/button-geel-V1.png',
        orderFullReport:    'src/assets/images/yesiwanttoorder.png',
        fullreport_url:     'https://academyofakaranumerology.com/products/akara-numerology-personal-blueprint-analysis',
        windowHeight:       window.innerHeight,
        windowWidth:        window.innerWidth,
        desktopVisible:     'inherit',
        mobileVisible:      'none',
        leftImageTop:       '0%',
        leftImageLeft:      '15%',
        leftImageWidth:     '39%',
        leftImageHeight:    '199%',
        blueprintanalysisLeft: '15%',
        blueprintPosition:  'absolute',
        blueprintTop:       '85%',
        blueprintLeft:      '19%',
        blueprintWidth:     '30%',
        blueprintHeight:    '90%',
        bluePosition:       'absolute',
        blueTop:            '15%',
        blueLeft:           '54%',
        blueTextLeft:       '55%',
        blueWidth:          '22%',
        blueTextWidth:      '20%',
        blueHeight:         '150%',
        onBlueTextPosition: 'relative',
        onBlueTextTop:      '-200px',
        fullreportPosition: 'relative',
        fullreportTop:      '0',
        dateInputWidth:     '30.5%',
        greyPartHeight:     '320px',
        headerMobilePosition: '-850px 0px',
        blueprintanalysisTextWidth: '535px',
        document:           null,
        getReadingButtonText: 'Get Your Reading!',
        getReadingButtonDisabled: false,
        getReadingButtonTimeout: null,
        onGetReadingLeft: '30px',
        lockedIcon: 'src/assets/images/lockedIcon.png',
    };
  },
  
  mounted() {
      this.$nextTick(() => {
          window.addEventListener('resize', this.onResize);
      })
  },

  beforeUnmount() { 
      window.removeEventListener('resize', this.onResize); 
  },
  
  created() {
    this.updateSizing();
    document.title = "Academy Of Akara Numerology";
 },

  methods: {
    onResize() {
      this.windowHeight = window.screen.height;
      this.windowWidth  = window.screen.width;

      this.updateSizing();
    },

    updateSizing() {
      // take the headerwidth, minus the windowwidth, divide by 2, add an offset to recenter to 178 pixels to the right of center. 
      this.headerMobilePosition = '-' + (((1920-this.windowWidth)/2)+178).toString() +'px 0px';
      this.blueprintanalysisTextWidth = (535 + ((this.windowWidth - 1400) / 520 * 165)).toString() + 'px';
      
      if (this.windowWidth >= 1400) {
        // desktop
        this.cropperWidth    = '250px';
        this.cropperHeight   = '250px';
        this.cropperTop      = '0px';
        this.cropperLeft     = '0px';
        this.desktopVisible  = 'inherit';
        this.mobileVisible   = 'none';

        this.leftImageTop    = '0%';
        this.leftImageLeft   = '15%';
        this.leftImageWidth  = '39%';
        this.leftImageHeight = '199%';

        this.blueprintanalysisLeft= '15%';

        this.blueprintPosition = 'absolute';
        this.blueprintTop    = '85%',
        this.blueprintLeft   = '19%',
        this.blueprintWidth  = '30%',
        this.blueprintHeight = 'auto',
        
        this.bluePosition    = 'absolute',
        this.blueTop         = '15%';
        this.blueLeft        = '54%';
        this.blueTextLeft    = '55%';
        this.blueWidth       = '22%';
        this.blueTextWidth   = '20%';
        this.blueHeight      = '150%';
        this.onBlueTextPosition   = 'relative';
        this.onBlueTextTop        = '0';

        this.fullreportPosition = 'relative';
        this.fullreportTop = '0';

        this.dateInputWidth  = '30.5%';
        this.greyPartHeight = '320px';
      } 
      else {
        // mobile
        this.cropperWidth    = (Math.min(this.windowWidth, 500) - 20).toString() + 'px';
        this.cropperHeight   = this.cropperWidth;
        this.cropperTop      = '300px';
        this.cropperLeft     = (Math.max(this.windowWidth - 500, 0) / 2).toString() + 'px';
        this.desktopVisible  = 'none';
        this.mobileVisible   = 'inherit';

        this.blueprintPosition = 'relative';
        this.leftImageTop    = '0%';
        this.leftImageLeft   = '0%';
        this.leftImageWidth  = '100%';
        this.leftImageHeight = '500%';

        this.blueprintanalysisLeft= '5%';

        this.blueprintTop    = '300%',
        this.blueprintLeft   = '0%',
        this.blueprintWidth  = '100%',
        this.blueprintHeight = '300%',

        this.bluePosition    = 'relative',
        this.blueTop         = '10px';
        this.blueLeft        = '0%';
        this.blueTextLeft    = '7.5%';
        this.blueWidth       = '90%';
        this.blueTextWidth   = '85%';
        this.blueHeight           = '650px';
        this.onBlueTextPosition   = 'relative';
        this.onBlueTextTop        = '-650px';

        this.fullreportPosition = 'relative';
        this.fullreportTop = '-750px';

        this.dateInputWidth  = '29.5%';
        this.greyPartHeight = '475px';
      } 
    },

    uploadImage(e) {
      const image = e.target.files[0];
      const reader = new FileReader();
      reader.readAsDataURL(image);
      reader.onload = e =>{
          this.previewImage = e.target.result;
          if (this.previewImage != '') {
            this.cropperVisible = 'visible';
          }
          else {
            this.cropperVisible = 'hidden';
          }
      };
    },

    loadCroppedImage() {
      const result = this.$refs.cropper.getResult();
      
      if (result.coordinates.width == 0) {
        this.croppedImageLoaded = false;
        this.createPDF();
        return;
      }
      const form = new FormData();

      result.canvas.toBlob(blob => {

        form.append('file', blob);
        const url = window.URL.createObjectURL(blob);
        this.croppedImage = url;
        this.croppedImageLoaded = true;

        this.createPDF();
      }, 'image/png');

    },

    setTextToDarkBlue(doc) {
      doc.setTextColor(19, 38, 82);
    },
    
    setTextToYellowOrange(doc) {
      doc.setTextColor(254, 205, 108);
    },

    setTextToGrey(doc) {
      doc.setTextColor(205, 210, 225);
    },

    setTextToLightYellow(doc) {
      doc.setTextColor(249, 217, 208);
    },

    drawBlockDarkBlue(doc, x, y, width, height) {
      doc.setFillColor(19, 38, 82);
      doc.rect(x, y, width, height, 'F');
    },

    drawBlockLightYellow(doc, x, y, width, height) {
      doc.setFillColor(249, 217, 208);
      doc.rect(x, y, width, height, 'F');
    },

    drawPage7(doc, a_soul) {
      doc.setFontSize(39);
      this.setTextToYellowOrange(doc);
      let ylinesize = 8;
      let x = 35;
      let y = 75;
      
      if (a_soul == 1) {
        doc.text("1: SOUL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The number 1 in the Soul position will generally manifest as"   , x, y += (ylinesize*2));
        doc.text("a person who has strong ideas and ideals, and who will need "   , x, y += ylinesize);
        doc.text("to learn how to integrate with other people."                   , x, y += ylinesize);
 
        doc.text("The 1 Soul can be 'quick from the gate,' and needs to learn"    , x, y += (ylinesize*2));
        doc.text("to follow through and completion. Then their inherent"          , x, y += ylinesize);
        doc.text("leadership qualities will be respected, and their grand"        , x, y += ylinesize);
        doc.text("plans can come to fruition. "                                   , x, y += ylinesize);

        doc.text("The 1 Soul enjoys independence and needs a certain amount of"   , x, y += (ylinesize*2));
        doc.text("free reign, even in group situations."                          , x, y += ylinesize);
      }
      else if (a_soul == 2) {
        this.setTextToYellowOrange(doc);
        doc.text("2: NEGATIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The 2 Soul is a vibration of harmony and longing."              , x, y += (ylinesize*2));

        doc.text("The number 2 represents relationships and 2s typically enjoy"   , x, y += (ylinesize*2));
        doc.text("companionship and being supportive to others."                  , x, y += ylinesize);

        doc.text("In their desire to please, however, they need to take care"     , x, y += (ylinesize*2));
        doc.text(" not to say 'Yes' when they really mean 'No!' Then their"       , x, y += ylinesize);
        doc.text("longing to belong will be tempered with their own true needs"   , x, y += ylinesize);
        doc.text("and desires."                                                   , x, y += ylinesize);

        doc.text("A developed 2 Soul can be quite the peacemaker and diplomat, "  , x, y += (ylinesize*2));
        doc.text("as well as negotiator. The 2 Soul is also good at seeing both"  , x, y += ylinesize);
        doc.text("sides of the story."                                            , x, y += ylinesize);
      }
      else if (a_soul == 3) {
        this.setTextToYellowOrange(doc);
        doc.text("3: POSITIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The 3 Soul is one of creativity, expression, and joy."          , x, y += (ylinesize*2));

        doc.text("Three in this position is constantly seeking ways to"           , x, y += (ylinesize*2));
        doc.text("express itself to the world. This drive can be displayed in"    , x, y += ylinesize);
        doc.text("the arts, theater, family, or even business."                   , x, y += ylinesize);

        doc.text("The person with a 3 Soul enjoys and needs appreciation in"      , x, y += (ylinesize*2));
        doc.text("their life, and any gesture of love or gratitude will"          , x, y += ylinesize);
        doc.text("always be warmly received. "                                    , x, y += ylinesize);

        doc.text("It is important for the 3 soul to find creative outlets of"     , x, y += (ylinesize*2));
        doc.text("expression, or they can become subject to worry and"            , x, y += ylinesize);
        doc.text("depression. Something as simple as working in a garden, or"     , x, y += ylinesize);
        doc.text("going to a social event, can shift the 3 Soul energy into"      , x, y += ylinesize);
        doc.text("optimism and joy."                                              , x, y += ylinesize);
      }
      else if (a_soul == 4) {
        this.setTextToYellowOrange(doc);
        doc.text("4: NEUTRAL MIND", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The number 4 in the Soul position represents someone who"       , x, y += (ylinesize*2));
        doc.text("enjoys the structure, order, and form of things."               , x, y += ylinesize);

        doc.text("'Give it to me in a box!' is an expression to which the"        , x, y += (ylinesize*2));
        doc.text("4 Soul can relate. "                                            , x, y += ylinesize);

        doc.text("Four in this position needs to take care to not overly"         , x, y += (ylinesize*2));
        doc.text("structure and organize their world or that of the people"       , x, y += ylinesize);
        doc.text("around them."                                                   , x, y += ylinesize);

        doc.text("The 4 Soul's true humanitarian spirit and service-oriented"     , x, y += (ylinesize*2));
        doc.text("nature will shine through when issues are dealt with using"     , x, y += ylinesize);
        doc.text("heart rather than head."                                        , x, y += ylinesize);
      }
      else if (a_soul == 5) {
        this.setTextToYellowOrange(doc);
        doc.text("5: PHYSICAL BODY", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("Five in this position has intense issues surrounding"         , x, y += (ylinesize*2));
        doc.text("personal freedom and free will."                              , x, y += ylinesize);
        
        doc.text("This 5 loves change, travel, and the option of changing"      , x, y += (ylinesize*2));
        doc.text("their mind, if they desire."                                  , x, y += ylinesize);
        
        doc.text("The 5 Soul is naturally very curious and has a keen sense"    , x, y += (ylinesize*2));
        doc.text("of adventure and discovery. The word 'routine' will sound"    , x, y += ylinesize);
        doc.text("like a death sentence, since they always need a certain"      , x, y += ylinesize);
        doc.text("amount of 'room to move' to feel comfortable. "               , x, y += ylinesize);
        
        doc.text("The 5 Soul is typically a very good communicator and enjoys"  , x, y += (ylinesize*2));
        doc.text("conversation and sharing ideas with others. "                 , x, y += ylinesize);
        
        doc.text("This ability to communicate well can manifest as someone"     , x, y += (ylinesize*2));
        doc.text("who is a natural teacher."                                    , x, y += ylinesize);
      }
      else if (a_soul == 6) {
        this.setTextToYellowOrange(doc);
        doc.text("6: ARCLINE", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The 6 Soul is a vibration of love and the appreciation of"    , x, y += (ylinesize*2));
        doc.text("family and beauty."                                           , x, y += ylinesize);

        doc.text("Six in this position also has a keen sense of justice and"    , x, y += (ylinesize*2));
        doc.text("probably coined the phrase, 'There ought to be a law!' "      , x, y += ylinesize);

        doc.text("Because of their sense of justice and fair play, these 6s"    , x, y += (ylinesize*2));
        doc.text("can be prone to feeling guilty at times. They can so dearly"  , x, y += ylinesize);
        doc.text("want things to go right, that if they don't, it is somehow "  , x, y += ylinesize);
        doc.text("experienced as their fault. "                                 , x, y += ylinesize);

        doc.text("Six is also the archetype of the Nurse or Caregiver. They"    , x, y += (ylinesize*2));
        doc.text("have a great sense of social responsibility and they hold"    , x, y += ylinesize);
        doc.text("family values near and dear."                                 , x, y += ylinesize);
      }
      else if (a_soul == 7) {
        this.setTextToYellowOrange(doc);
        doc.text("7: AURIC BODY", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The 7 Soul is a spirit of intelligence and wisdom."           , x, y += (ylinesize*2));

        doc.text("Seven is a very spiritual number and although the 7 Soul "    , x, y += (ylinesize*2));
        doc.text("may find themselves in the hustle and bustle of the world"    , x, y += ylinesize);
        doc.text("they will definitely need time to retreat into their own "    , x, y += ylinesize);
        doc.text("solitude.                                                "    , x, y += ylinesize);

        doc.text("This 'time unto oneself' has a tremendous regenerating   "    , x, y += (ylinesize*2));
        doc.text("power for their psyche, for it is then that they can more"    , x, y += ylinesize);
        doc.text("easily express their uplifting and generous nature.      "    , x, y += ylinesize);

        doc.text("The vibration of 7 relates to music, and typically the 7 "    , x, y += (ylinesize*2));
        doc.text("Soul finds music to be very elevating and healing.       "    , x, y += ylinesize);
      }
      else if (a_soul == 8) {
        this.setTextToYellowOrange(doc);
        doc.text("8: PRANIC BODY", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The 8 Soul enjoys power, acknowledgment, and the ability to" , x, y += (ylinesize*2));
        doc.text("create wealth. "  , x, y += ylinesize);

        doc.text("They will definitely be dealing with issues of power in " , x, y += (ylinesize*2));
        doc.text("life, and these individuals will never like to have their"  , x, y += ylinesize);
        doc.text("personal power threatened or taken away. "  , x, y += ylinesize);

        doc.text("Money can be important to 8s, as they usually have a good"  , x, y += (ylinesize*2));
        doc.text("business sense, and the ability to create their fortune." , x, y += ylinesize);
        doc.text("The number 8 often deals with emotional issues surrounding" , x, y += ylinesize);
        doc.text("fear, and may at times deal with intense fears despite a" , x, y += ylinesize);
        doc.text("facade of bravado. "  , x, y += ylinesize);

        doc.text("Aerobic exercise is important for 8s, as the number 8"  , x, y += (ylinesize*2));
        doc.text("represents the breath and the ability to be fearless."  , x, y += ylinesize);
      }
      else if (a_soul == 9) {
        this.setTextToYellowOrange(doc);
        doc.text("9: SUBTLE BODY", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        doc.text("The 9 Soul is one of sensitivity, high ideals, and intense"   , x, y += (ylinesize*2));
        doc.text("emotions. "   , x, y += ylinesize);
        
        doc.text("The 9 Soul can be very psychic, and this psychic ability"   , x, y += (ylinesize*2));
        doc.text("can at times be challenging. "    , x, y += ylinesize);

        doc.text("These individuals need to learn to distinguish between "    , x, y += (ylinesize*2));
        doc.text("their own thoughts and emotions, and those of others."    , x, y += ylinesize);
        doc.text("Nine in this position is very adept at reading between the"   , x, y += ylinesize);
        doc.text("lines and picking up on information based on what is not "    , x, y += ylinesize);
        doc.text("spoken, as well as what is."    , x, y += ylinesize);
        
        doc.text("They usually enjoy a softer and gentler approach to life,"    , x, y += (ylinesize*2));
        doc.text("but when pushed too far, can be intensely reactive."    , x, y += ylinesize);
        
        doc.text("The power of suggestion works well when communicating "   , x, y += (ylinesize*2));
        doc.text("to a 9. Nines can read the subtle cues."    , x, y += ylinesize);
      }
      else if (a_soul == 10) {
        this.setTextToYellowOrange(doc);
        doc.text("10: RADIANT BODY", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The 10 Soul has a personal flair and style all its own!", x, y += (ylinesize*2));
        doc.text("They love to lead the way, and the 10's motto is 'All or", x, y += ylinesize);
        doc.text("Nothing!' ", x, y += ylinesize);

        doc.text("They are either highly motivated by a project, ideal, or", x, y += (ylinesize*2));
        doc.text("philosophy; or they couldn't be bothered at all. ", x, y += ylinesize);

        doc.text("These souls need to learn balance, so that they are not", x, y += (ylinesize*2));
        doc.text("vacillating between extremes in their lives.", x, y += ylinesize);

        doc.text("The sweeping highs and lows which they can experience will", x, y += (ylinesize*2));
        doc.text("begin to drain their nervous systems, and this is their", x, y += ylinesize);
        doc.text("Achilles heel.", x, y += ylinesize);

        doc.text("Balance and honoring oneself will be important lessons", x, y += (ylinesize*2));
        doc.text("for the 10 Soul to learn. ", x, y += ylinesize);

        doc.text("The 10 enjoys having authority, nobility, and excellence.", x, y += (ylinesize*2));
      }
      else if (a_soul == 11) {
        this.setTextToYellowOrange(doc);
        doc.text("11: LIGHTHOUSE", x, y);

        doc.setFontSize(12);
        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The 11 Soul is one of integrity, wisdom, ", x, y += (ylinesize*2));
        doc.text("and spiritual vision.", x, y += ylinesize);

        doc.text("This 11 will need to create a life of higher values, or", x, y += (ylinesize*2));
        doc.text("devotion to a great cause or ideal. If these 11s are not", x, y += ylinesize);
        doc.text("taking the 'high road' of life, it will cause them distress", x, y += ylinesize);
        doc.text("and conflict, particularly in the area of their ", x, y += ylinesize);
        doc.text("relationships. ", x, y += ylinesize);

        doc.text("This is where the 11 Soul will be tested in life. Will they", x, y += (ylinesize*2));
        doc.text("allow for the way of the world to prevail, or will they", x, y += ylinesize);
        doc.text("strive towards relationships of character and integrity?", x, y += ylinesize);

        doc.text("Through their life experience, they will learn how to", x, y += (ylinesize*2));
        doc.text("communicate and elevate others with their unique insights", x, y += ylinesize);
        doc.text("and wisdom.", x, y += ylinesize);
      }
    },

    drawPage8(doc, a_mastery) {
      doc.setFontSize(39);
      this.setTextToYellowOrange(doc);
      let ylinesize = 8;
      let x = 35;
      let y = 96;
      
      if (a_mastery == 1) {
        doc.text("1: SOUL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The number 1 in the Mastery position will require a person", x, y += (ylinesize*2));
        doc.text("to learn lessons surrounding male energy (theirs and others), ", x, y += ylinesize);
        doc.text("and the ability to stand on one's own feet. ", x, y += ylinesize);
        doc.text("Independence, courage, and coming from the heart are all ", x, y += (ylinesize*2));
        doc.text("part of the 1 Mastery.", x, y += ylinesize);
      }
      else if (a_mastery == 2) {
        doc.text("2: NEGATIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("Two in the Mastery position will require these individuals", x, y += (ylinesize*2));
        doc.text("to learn lessons about cooperation and obedience in their", x, y += ylinesize);
        doc.text("life. ", x, y += ylinesize);
        doc.text("They will typically find themselves over-cooperating at", x, y += (ylinesize*2));
        doc.text("times, until they can learn to establish healthy boundaries.", x, y += ylinesize);
        doc.text("This awareness will allow them to not feel taken advantage", x, y += (ylinesize*2));
        doc.text("of, or used. The ability to develop well-placed trust will", x, y += ylinesize);
        doc.text("also be important for the 2 Mastery. ", x, y += ylinesize);
        doc.text("This can manifest in a relationship with a coach, teacher,", x, y += (ylinesize*2));
        doc.text("or someone who has a guiding influence in their life.", x, y += ylinesize);
      }
      else if (a_mastery == 3) {
        doc.text("3: POSITIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("The number 3 in the Mastery position will challenge an", x, y += (ylinesize*2));
        doc.text("individual in the areas of their personal security and ", x, y += ylinesize);
        doc.text("self-confidence. ", x, y += ylinesize);
        doc.text("This number 3 can become insecure or worried in a flash.", x, y += (ylinesize*2));
        doc.text("There may also be self-destructive tendencies at times, ", x, y += ylinesize);
        doc.text("whether obvious or hidden.", x, y += ylinesize);
        doc.text("This is the position where the number 3 needs to be caring", x, y += (ylinesize*2));
        doc.text("and nurturing to its own self first, so it will have the ", x, y += ylinesize);
        doc.text("self-security, joy, and sense of abundance that can be", x, y += ylinesize);
        doc.text("shared with other people.", x, y += ylinesize);
        doc.text("Then life will be mutually nurturing and joyful.", x, y += (ylinesize*2));
      }
      else if (a_mastery == 4) {
        doc.text("4: NEUTRAL MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Four in the Mastery position signifies someone who will", x, y += (ylinesize*2));
        doc.text("need to find a meaningful life work, a project, venture,", x, y += ylinesize);
        doc.text("or idea into which they can invest their heart and soul.", x, y += ylinesize);
        doc.text("When they are in the process of their work, it is important", x, y += (ylinesize*2));
        doc.text("for them not to skip steps, as this can sometimes be the", x, y += ylinesize);
        doc.text("downfall of the 4 Mastery.", x, y += ylinesize);
        doc.text("Attention to order, sequence, and follow through are all a", x, y += (ylinesize*2));
        doc.text("part of the 4's Mastery lessons.", x, y += ylinesize);
        doc.text("From time to time it will be important for this individual", x, y += (ylinesize*2));
        doc.text("to give something back to humanity, in the form of selfless", x, y += ylinesize);
        doc.text("service. This inspires humility, which is an important", x, y += ylinesize);
        doc.text("virtue for the 4 Mastery to develop in life.", x, y += ylinesize);
      }
      else if (a_mastery == 5) {
        doc.text("5: PHYSICAL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Five in the Mastery position will require a person to learn", x, y += (ylinesize*2));
        doc.text("lessons about consistency and accountability. Since the", x, y += ylinesize);
        doc.text("number 5 so much enjoys change, 5 in this position can", x, y += ylinesize);
        doc.text("typify a person who is a jack-of-all-trades and master of", x, y += ylinesize);
        doc.text("none. ", x, y += ylinesize);
        doc.text("Five in the Mastery position will force these individuals", x, y += (ylinesize*2));
        doc.text("to go through experiences of feeling confined or restricted", x, y += ylinesize);
        doc.text("by others, or their environments.", x, y += ylinesize);
        doc.text("They will need to transcend this experience of restriction", x, y += (ylinesize*2));
        doc.text("by realizing that they are truly free within themselves,", x, y += ylinesize);
        doc.text("regardless of their outer world.", x, y += ylinesize);
      }
      else if (a_mastery == 6) {
        doc.text("6: ARCLINE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Six in the Mastery position will require a person to learn", x, y += (ylinesize*2));
        doc.text("lessons of self-esteem and self-protection.", x, y += ylinesize);
        doc.text("When the Arc Line or Halo is weak, it will create a shaky", x, y += (ylinesize*2));
        doc.text("or unprotected feeling.", x, y += ylinesize);
        doc.text("There is a direct connection between self-esteem, the", x, y += (ylinesize*2));
        doc.text("strength of the Arc Line, and the health and vitality of", x, y += ylinesize);
        doc.text("the immune system. ", x, y += ylinesize);
        doc.text("With the 6 in this position, if a person feels run over by", x, y += (ylinesize*2));
        doc.text("the world, they will have the tendency to fall ill.", x, y += ylinesize);
        doc.text("The number 6 also represents meditation, and meditation is", x, y += (ylinesize*2));
        doc.text("essential for the 6 Mastery.", x, y += ylinesize);
      }
      else if (a_mastery == 7) {
        doc.text("7: AURA", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Seven in the Mastery position will require these ", x, y += (ylinesize*2));
        doc.text("individuals to learn how to elevate themselves, so they", x, y += ylinesize);
        doc.text("will be able to elevate others as well.", x, y += ylinesize);
        doc.text("Learning how to 'speak up!' so that others will know how", x, y += (ylinesize*2));
        doc.text("they feel is also part of the 7 Mastery. ", x, y += ylinesize);
        doc.text("They do not relate well to orders, and they would prefer", x, y += (ylinesize*2));
        doc.text("explanations or instructions, since the number 7 represents", x, y += ylinesize);
        doc.text("the intelligence.", x, y += ylinesize);
        doc.text("Never say, 'Because I say so!' to a 7, they will resent you.", x, y += (ylinesize*2));
        doc.text("These 7s are very sensitive to their surrounding space, and", x, y += ylinesize);
        doc.text("they do not feel or work well in cluttered environments.", x, y += ylinesize);
      }
      else if (a_mastery == 8) {
        doc.text("8: PRANIC BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Mastery of 8 will give these individuals life lessons", x, y += (ylinesize*2));
        doc.text("that will help them to establish their own sense of ", x, y += ylinesize);
        doc.text("personal power.", x, y += ylinesize);
        doc.text("Eight represents money, and the 8 Mastery will provide", x, y += (ylinesize*2));
        doc.text("opportunities to examine their concept of money.", x, y += ylinesize);
        doc.text("Whether they want to hoard it all or give it away, money", x, y += (ylinesize*2));
        doc.text("and power are the theme of the day! ", x, y += ylinesize);
        doc.text("The 8 Mastery can also be a vehicle for learning about ", x, y += (ylinesize*2));
        doc.text("healing, either their own or others. They might have to ", x, y += ylinesize);
        doc.text("deal with bouts of ill health in their life or be the", x, y += ylinesize);
        doc.text("caretaker for someone else who is ill.", x, y += ylinesize);
        doc.text("This experience can attract someone to the healing arts, ", x, y += (ylinesize*2));
        doc.text("as well.", x, y += ylinesize);
      }
      else if (a_mastery == 9) {
        doc.text("9: SUBTLE BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Nine in the Mastery will often bring a person lessons", x, y += (ylinesize*2));
        doc.text("surrounding completion, such as completing tasks or", x, y += ylinesize);
        doc.text("projects, and not procrastinating. ", x, y += ylinesize);
        doc.text("This can be a shortcoming of the 9 Mastery. ", x, y += (ylinesize*2));
        doc.text("Nine is a feminine energy number, and lessons relating to", x, y += ylinesize);
        doc.text("female energy, their own or others, will be experienced.", x, y += ylinesize);
        doc.text("It might be a boy who grows up with three sisters and no", x, y += (ylinesize*2));
        doc.text("father, where he is constantly learning about the female", x, y += ylinesize);
        doc.text("psyche. ", x, y += ylinesize);
        doc.text("It can be a woman who is attracted to interior design, ", x, y += (ylinesize*2));
        doc.text("where she is dealing with the subtleties of color, shape,", x, y += ylinesize);
        doc.text("and form. ", x, y += ylinesize);
        doc.text("It is important for the 9 Mastery to develop an area of", x, y += (ylinesize*2));
        doc.text("personal expertise, since the number 9 represents mastery.", x, y += ylinesize);
        doc.text("This sense of mastery will help a person to feel", x, y += (ylinesize*2));
        doc.text("accomplished and fulfilled.", x, y += ylinesize);
      }
      else if (a_mastery == 10) {
        doc.text("10: RADIANT BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Mastery of 10 will require someone to deal with issues of", x, y += (ylinesize*2));
        doc.text("authority, leadership, and the ability to come through. ", x, y += ylinesize);
        doc.text("There will be times when these 10s will want to stay at ", x, y += (ylinesize*2));
        doc.text("ease, but the development of their life will require them", x, y += ylinesize);
        doc.text("to step forward.", x, y += ylinesize);
        doc.text("They must learn how to lead, even if it is simply within", x, y += (ylinesize*2));
        doc.text("their immediate family. Once they learn how to do this,", x, y += ylinesize);
        doc.text("their drive and presence can be very inspirational.", x, y += ylinesize);
        doc.text("There's no stopping a fired-up 10!", x, y += (ylinesize*2));
      }
      else if (a_mastery == 11) {
        doc.text("11: LIGHTHOUSE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Mastery of 11 will challenge a person in the area of", x, y += (ylinesize*2));
        doc.text("personal discipline and the willingness to subject oneself", x, y += ylinesize);
        doc.text("to reach the object.", x, y += ylinesize);
        doc.text("Devotion is an important aspect of the 11 Mastery, and if", x, y += (ylinesize*2));
        doc.text("these individuals are able to devote and discipline", x, y += ylinesize);
        doc.text("themselves to a higher ideal in life, they can be very", x, y += ylinesize);
        doc.text("successful.", x, y += ylinesize);
        doc.text("Whether orthodox, or simply through an inner knowing and", x, y += (ylinesize*2));
        doc.text("understanding, their spiritual life will be a great ", x, y += ylinesize);
        doc.text("barometer for their personal success and fulfillment.", x, y += ylinesize);
        doc.text("If the 11 Mastery is not living up to their highest", x, y += (ylinesize*2));
        doc.text("potential, it will cause them pain and duality. ", x, y += ylinesize);
        doc.text("In the positive pole, the 11 can find spiritual truth and", x, y += (ylinesize*2));
        doc.text("insight for all.", x, y += ylinesize);
      }
    },

    drawPage9(doc, a_gift) {
      doc.setFontSize(39);
      this.setTextToYellowOrange(doc);
      let ylinesize = 8;
      let x = 35;
      let y = 100;
      
      if (a_gift == 0) {
        doc.text("0: GIFT OF SALVATION", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("The gift of 0 is the gift of salvation. It means that if", x, y += (ylinesize*2));
        doc.text("you walk on a spiritual path in your life, you will have", x, y += ylinesize);
        doc.text("the power to bring many souls to their salvation.", x, y += ylinesize);
        doc.text("The negative aspect of this gift is that if you choose not", x, y += (ylinesize*2));
        doc.text("to live in conscious relationship with your own higher", x, y += ylinesize);
        doc.text("consciousness, your gift of salvation will manifest as the", x, y += ylinesize);
        doc.text("ability to destroy the lives of as many people as you would", x, y += ylinesize);
        doc.text("have saved had you kept up on a spiritual path. ", x, y += ylinesize);
        doc.text("It is a very 'black and white' gift... there is no middle", x, y += (ylinesize*2));
        doc.text("ground here.", x, y += ylinesize);
      }
      else if (a_gift == 1) {
        doc.text("1: SOUL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("A Gift of 1 gives a person an individual spark and flare.", x, y += (ylinesize*2));
        doc.text("The 1 Gift person is full of original ideas, and would like", x, y += (ylinesize*2));
        doc.text("to lead the way in actualizing them! Never at a loss for", x, y += ylinesize);
        doc.text("enthusiasm, the 1 Gift has a tremendous power to motivate", x, y += ylinesize);
        doc.text("and inspire others. ", x, y += ylinesize);
        doc.text("'There are no problems, only solutions.' would be an", x, y += (ylinesize*2));
        doc.text("appropriate motto for the 1 Gift.", x, y += ylinesize);
      }
      else if (a_gift == 2) {
        doc.text("2: NEGATIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("A Gift of 2 will display itself as someone who creates", x, y += (ylinesize*2));
        doc.text("harmony and balance in all environments. ", x, y += ylinesize);
        doc.text("'Let's get along!'' is the motto of the 2 Gift, and they", x, y += (ylinesize*2));
        doc.text("can make it so.", x, y += ylinesize);
        doc.text("This 2 has a keen eye for detail and the ability to sense", x, y += (ylinesize*2));
        doc.text("what can go wrong in a situation before it does so. Two in", x, y += ylinesize);
        doc.text("the positive pole has a protective quality. Protection, ", x, y += ylinesize);
        doc.text("caring, and diplomacy are all part of the 2 Gift.", x, y += ylinesize);
      }
      else if (a_gift == 3) {
        doc.text("3: POSITIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 3 displays itself as a person who can see the", x, y += (ylinesize*2));
        doc.text("positive in any situation, and create solutions for others.", x, y += ylinesize);
        doc.text("'Never say never!' is the motto of the 3 Gift.", x, y += (ylinesize*2));
        doc.text("Three can have an entrepreneurial flair as well, and turn", x, y += (ylinesize*2));
        doc.text("night into day when motivated. There is typically a", x, y += ylinesize);
        doc.text("humanitarian element to the 3 here, and caring for one's", x, y += ylinesize);
        doc.text("family and fellow man comes naturally to the 3 Gift.", x, y += ylinesize);
      }
      else if (a_gift == 4) {
        doc.text("4: NEUTRAL MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 4 exemplifies the qualities of hard work, a", x, y += (ylinesize*2));
        doc.text("neutral and balanced mind, and the ability to offer counsel", x, y += ylinesize);
        doc.text("and support to others. ", x, y += ylinesize);
        doc.text("Four is the 'Rock of Gibraltar' archetype, in this position.", x, y += (ylinesize*2));
        doc.text("Steady, trustworthy, and dependable are all typical 4 Gift", x, y += (ylinesize*2));
        doc.text("qualities.", x, y += ylinesize);
        doc.text("The number 4 also represents words, and a 4 gift can give", x, y += (ylinesize*2));
        doc.text("someone the ability to choose their words and language well.", x, y += ylinesize);
      }
      else if (a_gift == 5) {
        doc.text("5: PHYSICAL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 5 exemplifies someone who can communicate well,", x, y += (ylinesize*2));
        doc.text("has natural teaching ability, and can adapt well in a wide", x, y += ylinesize);
        doc.text("range of environments.", x, y += ylinesize);
        doc.text("A Gift of 5 will generally give a person a very sturdy and", x, y += (ylinesize*2));
        doc.text("healthy constitution, since 5 represents the physical body.", x, y += ylinesize);
        doc.text("Five additionally represents commerce and business, and a 5", x, y += (ylinesize*2));
        doc.text("Gift can manifest as good business sense, as well as the", x, y += (ylinesize*2));
        doc.text("ability to barter and trade well. ", x, y += ylinesize);
        doc.text("You'll feel hard pressed if you try to out-negotiate a Gift", x, y += (ylinesize*2));
        doc.text("of 5 at the merchants' bazaar!", x, y += ylinesize);
      }
      else if (a_gift == 6) {
        doc.text("6: ARCLINE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 6 is characterized by social awareness, an", x, y += (ylinesize*2));
        doc.text("appreciation of family traditions, and a natural sense of", x, y += ylinesize);
        doc.text("beauty and grace. With 6 in this position, a person will", x, y += ylinesize);
        doc.text("typically be involved in the concerns and welfare of others.", x, y += ylinesize);
        doc.text("These individuals might enjoy volunteering, serving within", x, y += (ylinesize*2));
        doc.text("a religious order, or helping in an organization such as", x, y += ylinesize);
        doc.text("Amnesty International.", x, y += ylinesize);
        doc.text("Their ability to focus, combined with an understanding of", x, y += (ylinesize*2));
        doc.text("justice and fair play, make the 6 Gift a valuable asset in", x, y += ylinesize);
        doc.text("any organization.", x, y += ylinesize);
      }
      else if (a_gift == 7) {
        doc.text("7: AURA", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 7 will give a person the ability to elevate", x, y += (ylinesize*2));
        doc.text("others and to create solutions through intelligence. Seven", x, y += ylinesize);
        doc.text("as a Gift has a keen analytical ability. ", x, y += ylinesize);
        doc.text("It is a detective-like quality that enjoys sifting through", x, y += (ylinesize*2));
        doc.text("information. ", x, y += ylinesize);
        doc.text("Many 7s are avid readers, and they often have writing ", x, y += (ylinesize*2));
        doc.text("skills as well. The Gift of 7 can express itself as someone", x, y += ylinesize);
        doc.text("who is inventive, particularly in the area of science. ", x, y += ylinesize);
        doc.text("The number 7 represents music, and the 7 Gift may have", x, y += (ylinesize*2));
        doc.text("singing or musical talent.", x, y += ylinesize);
      }
      else if (a_gift == 8) {
        doc.text("8: PRANIC BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("The Gift of 8 can express itself as someone who has a good", x, y += (ylinesize*2));
        doc.text("executive mind and the ability to encourage and lead others.", x, y += ylinesize);
        doc.text("Eight in this position is a very positive and lively energy.", x, y += (ylinesize*2));
        doc.text("Eight is Energy! It is also the ability to heal, and often", x, y += (ylinesize*2));
        doc.text("the 8 Gift has natural healing talents.", x, y += ylinesize);
        doc.text("It can even manifest through their presence, or kind word", x, y += (ylinesize*2));
        doc.text("and gesture. Eight in this position is very multifaceted", x, y += ylinesize);
        doc.text("and can create wealth, healing, and opportunities through", x, y += ylinesize);
        doc.text("many avenues.", x, y += ylinesize);
      }
      else if (a_gift == 9) {
        doc.text("9: SUBTLE BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 9 is characterized as someone who is a quick", x, y += (ylinesize*2));
        doc.text("learner, has an eye for detail, and is intuitive. ", x, y += ylinesize);
        doc.text("The 9 Gift has the drive and ambition, and they can ", x, y += (ylinesize*2));
        doc.text("complete the task before them.", x, y += ylinesize);
        doc.text("They often have an appreciation for the arts and music,", x, y += (ylinesize*2));
        doc.text("since the number nine enjoys artistic excellence and ", x, y += ylinesize);
        doc.text("mastery. These 9s can be very sentimental as well, often", x, y += ylinesize);
        doc.text("having their treasured memories of the past surrounding", x, y += ylinesize);
        doc.text("them in their home. As much as they enjoy the softness,", x, y += ylinesize);
        doc.text("subtlety, and sophistication of life, they are not to be", x, y += ylinesize);
        doc.text("pushed too far or they can react with ruthless intensity.", x, y += ylinesize);
      }
      else if (a_gift == 10) {
        doc.text("10: RADIANT BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 10 will display itself as someone who has spark", x, y += (ylinesize*2));
        doc.text("and enthusiasm. They can lead the way, and would typically", x, y += ylinesize);
        doc.text("like to have their way! Ten is the archetype of the warrior,", x, y += ylinesize);
        doc.text("as well as the King or Queen.", x, y += ylinesize);
        doc.text("So be aware that this individual knows how to fight, as", x, y += (ylinesize*2));
        doc.text("well as how to wear the crown. Many politicians and", x, y += ylinesize);
        doc.text("professional athletes have the number 10 in their numerology.", x, y += ylinesize);
        doc.text("Their radiant charisma naturally draws people to them, and", x, y += (ylinesize*2));
        doc.text("these 10s enjoy the applause of others.", x, y += ylinesize);
      }
      else if (a_gift == 11) {
        doc.text("11: LIGHTHOUSE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Gift of 11 can display itself in a wide range of", x, y += (ylinesize*2));
        doc.text("possibilities. What these possibilities are will depend", x, y += ylinesize);
        doc.text("largely on the other numbers present in the basic formula.", x, y += ylinesize);
        doc.text("Eleven as a Gift offers many avenues of expression and", x, y += (ylinesize*2));
        doc.text("talent, since 11 embodies the positive characteristics of", x, y += ylinesize);
        doc.text("all the numbers. ", x, y += ylinesize);
        doc.text("It is considered a Master number for this reason, and this", x, y += (ylinesize*2));
        doc.text("brings great versatility. ", x, y += ylinesize);
        doc.text("The Gift of 11 is someone who would like to hold the", x, y += (ylinesize*2));
        doc.text("lantern of truth high, so that all may find their way.", x, y += ylinesize);
      }
    },

    drawPage10(doc, a_knowledge) {
      doc.setFontSize(39);
      this.setTextToYellowOrange(doc);
      let ylinesize = 8;
      let x = 35;
      let y = 85;
      
      if (a_knowledge == 1) {
        doc.text("1: SOUL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("One in the Knowledge position means that a person has been", x, y += (ylinesize*2));
        doc.text("in leadership roles for many lifetimes. They have also been", x, y += ylinesize);
        doc.text("the starting point for many great projects and ideas that", x, y += ylinesize);
        doc.text("have affected other people's lives as well. ", x, y += ylinesize);
        doc.text("One in this position signifies someone who can start with", x, y += (ylinesize*2));
        doc.text("nothing, and create everything from their spirit and vision.", x, y += ylinesize);
      }
      else if (a_knowledge == 2) {
        doc.text("2: NEGATIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("Two in the Knowledge position exemplifies someone who has", x, y += (ylinesize*2));
        doc.text("been working toward creating peace and harmony for many", x, y += ylinesize);
        doc.text("lifetimes. ", x, y += ylinesize);
        doc.text("It is also someone who has been learning many of life's", x, y += (ylinesize*2));
        doc.text("lessons through relationships. ", x, y += ylinesize);
        doc.text("It suggests an individual who has an inherent understanding", x, y += (ylinesize*2));
        doc.text("of loyalty, trust, and obedience.", x, y += ylinesize);
      }
      else if (a_knowledge == 3) {
        doc.text("3: POSITIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Three in the Knowledge position signifies someone who has", x, y += (ylinesize*2));
        doc.text("been caring for and nurturing others for many lifetimes.", x, y += ylinesize);
        doc.text("The number 3 here can also manifest a person who is handy", x, y += (ylinesize*2));
        doc.text("in the kitchen, as 3 represents food. With the number 3 in", x, y += ylinesize);
        doc.text("this position, a creative and positive solution is never ", x, y += ylinesize);
        doc.text("far away. ", x, y += ylinesize);
        doc.text("Three also represents the Positive Mind, which is the", x, y += (ylinesize*2));
        doc.text("ability to find the good in any circumstance and a", x, y += ylinesize);
        doc.text("solution for all.", x, y += ylinesize);
      }
      else if (a_knowledge == 4) {
        doc.text("4: NEUTRAL MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("The number 4 in the Knowledge position means that you have", x, y += (ylinesize*2));
        doc.text("been of service, and worked hard to create structure, form", x, y += ylinesize);
        doc.text("and order in the world for many lifetimes.", x, y += ylinesize);
        doc.text("It can also manifest in a natural counseling ability, as 4", x, y += (ylinesize*2));
        doc.text("represents the Neutral Mind. ", x, y += ylinesize);
        doc.text("This sense of neutrality and balance can lend support and", x, y += (ylinesize*2));
        doc.text("comfort to others, as well as offer practical solutions", x, y += ylinesize);
        doc.text("where there may be conflict.", x, y += ylinesize);
      }
      else if (a_knowledge == 5) {
        doc.text("5: PHYSICAL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Five in the Knowledge position means that a person has been", x, y += (ylinesize*2));
        doc.text("in the role of a teacher or communicator for many lifetimes", x, y += ylinesize);
        doc.text("Five in this position will usually give a person good ", x, y += (ylinesize*2));
        doc.text("health and endurance. ", x, y += ylinesize);
        doc.text("Additionally, a Knowledge of 5 can be someone who has ", x, y += (ylinesize*2));
        doc.text("worked as a freedom fighter in many lifetimes, creating", x, y += ylinesize);
        doc.text("social change and revolution.", x, y += ylinesize);
      }
      else if (a_knowledge == 6) {
        doc.text("6: ARCLINE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Six in the Knowledge position represents many lifetimes of", x, y += (ylinesize*2));
        doc.text("serving and caring for others.", x, y += ylinesize);
        doc.text("This may have been in the traditional family, or within a", x, y += (ylinesize*2));
        doc.text("school or other institution.", x, y += ylinesize);
        doc.text("The number 6 also describes a person who has a natural", x, y += (ylinesize*2));
        doc.text("sense of the sacred and an affinity toward prayer.", x, y += ylinesize);
        doc.text("It may also be someone who has been involved in the", x, y += (ylinesize*2));
        doc.text("protection of others.", x, y += ylinesize);
      }
      else if (a_knowledge == 7) {
        doc.text("7: AURA", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Seven as the Knowledge often represents someone who has", x, y += (ylinesize*2));
        doc.text("spent many lifetimes in a monastic environment, away from", x, y += ylinesize);
        doc.text("the world. The archetype of the priest lighting the candle,", x, y += ylinesize);
        doc.text("the nun praying over her rosary, and the monk in seclusion", x, y += ylinesize);
        doc.text("are all part of the 7 Knowledge.", x, y += ylinesize);
        doc.text("These individuals have worked many lifetimes to elevate", x, y += (ylinesize*2));
        doc.text("themselves, and they have not typically walked the way of", x, y += ylinesize);
        doc.text("the world.", x, y += ylinesize);
        doc.text("Seven as the Knowledge can also be someone who has done", x, y += (ylinesize*2));
        doc.text("intense study and research for many lifetimes, in order", x, y += ylinesize);
        doc.text("to bring this wealth of information to the present world.", x, y += ylinesize);
      }
      else if (a_knowledge == 8) {
        doc.text("8: PRANIC BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Knowledge of 8 is someone who is accustomed to power and", x, y += (ylinesize*2));
        doc.text("authority.", x, y += ylinesize);
        doc.text("Through many lifetimes, these 8s have been involved in", x, y += (ylinesize*2));
        doc.text("creating wealth or have worked in the healing profession.", x, y += ylinesize);
        doc.text("Leadership is an area they have dealt with often, and these", x, y += (ylinesize*2));
        doc.text("individuals can usually hold their own in any confrontation.", x, y += ylinesize);
      }
      else if (a_knowledge == 9) {
        doc.text("9: SUBTLE BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Nine in the Knowledge position gives a person a deep", x, y += (ylinesize*2));
        doc.text("understanding of female energy.", x, y += ylinesize);
        doc.text("Their ability to master things stretches back through many", x, y += (ylinesize*2));
        doc.text("lifetimes, and at a young age, they may display a natural", x, y += ylinesize);
        doc.text("ability in the arts or science. ", x, y += ylinesize);
        doc.text("These 9s never need to be caught off guard by anything,", x, y += (ylinesize*2));
        doc.text("if they just listen to their own inner voice.", x, y += ylinesize);
      }
      else if (a_knowledge == 10) {
        doc.text("10: RADIANT BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("Ten in the Knowledge position represents someone who has", x, y += (ylinesize*2));
        doc.text("walked on the battlefield of life, literally and", x, y += ylinesize);
        doc.text("figuratively, for many lifetimes.", x, y += ylinesize);
        doc.text("They have died in battle before and have held leadership", x, y += (ylinesize*2));
        doc.text("roles on and off this field of life. The elements of wealth", x, y += ylinesize);
        doc.text("and royalty will usually agree with these 10s, and there", x, y += ylinesize);
        doc.text("may be actual royal blood in their family tree.", x, y += ylinesize);
      }
      else if (a_knowledge == 11) {
        doc.text("11: LIGHTHOUSE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Knowledge of 11 means that these individuals have been", x, y += (ylinesize*2));
        doc.text("working with others for many lifetimes to bring truth and", x, y += ylinesize);
        doc.text("light to humanity.", x, y += ylinesize);
        doc.text("This will have manifested in many ways, but there will be", x, y += (ylinesize*2));
        doc.text("the common thread of sacrifice, higher ideals, and spiritual", x, y += ylinesize);
        doc.text("vision woven throughout their life experience.", x, y += ylinesize);
        doc.text("These 11s have much wisdom to draw on from their archival", x, y += (ylinesize*2));
        doc.text("memory bank.", x, y += ylinesize);
      }
    },

    drawPage11(doc, a_path) {
      doc.setFontSize(39);
      this.setTextToYellowOrange(doc);
      let ylinesize = 8;
      let x = 35;
      let y = 96;
      
      if (a_path == 1) {
        doc.text("1: SOUL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("A Path of 1 will calculate to be a 10. This Path is one of", x, y += (ylinesize*2));
        doc.text("leadership, taking responsibility, and making a difference.", x, y += ylinesize);
        doc.text("The great spirit who has a 10 Path must deliver, as 10 is 1", x, y += (ylinesize*2));
        doc.text("amplified, and with these extra gifts and talents a person ", x, y += ylinesize);
        doc.text("needs to come through. ", x, y += ylinesize);
        doc.text("If not, their life will feel unfulfilled and restless.", x, y += (ylinesize*2));
      }
      else if (a_path == 2) {
        doc.text("2: NEGATIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);

        doc.text("A 2 Path means that someone will find much of their life's", x, y += (ylinesize*2));
        doc.text("fulfillment in relation to and in communication with other", x, y += ylinesize);
        doc.text("people.", x, y += ylinesize);
        doc.text("Whether a tour guide, the right-hand man or woman behind the", x, y += (ylinesize*2));
        doc.text("chief executive, or even a politician, the  2 Path will", x, y += ylinesize);
        doc.text("strive to be involved with others in a  significant way.", x, y += ylinesize);
      }
      else if (a_path == 3) {
        doc.text("3: POSITIVE MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("The 3 Path will express itself in many ways. It can be an", x, y += (ylinesize*2));
        doc.text("individual who gains great fulfillment through caring for", x, y += ylinesize);
        doc.text("family and nurturing others. ", x, y += ylinesize);
        doc.text("It might be someone who enjoys inspiring others, as in", x, y += (ylinesize*2));
        doc.text("motivational speaking.", x, y += ylinesize);
        doc.text("The arts or entertainment could be appealing as well.", x, y += (ylinesize*2));
        doc.text("Of the many avenues of opportunity open on the 3 Path, a", x, y += (ylinesize*2));
        doc.text("sense of creativity and connectedness with others will", x, y += ylinesize);
        doc.text("reap the greatest rewards.", x, y += ylinesize);
      }
      else if (a_path == 4) {
        doc.text("4: NEUTRAL MIND", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Path of 4 will bring a person into a life where they can", x, y += (ylinesize*2));
        doc.text("be of service, or to a work within some type of structure", x, y += ylinesize);
        doc.text("where their dedication and loyalty will be appreciated.", x, y += ylinesize);
        doc.text("The number 4 in this position has a more traditional and", x, y += (ylinesize*2));
        doc.text("conservative style, and typically people with a 4 Path are", x, y += ylinesize);
        doc.text("the pillars of society.", x, y += ylinesize);
      }
      else if (a_path == 5) {
        doc.text("5: PHYSICAL BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("The Path of 5 will give the desire to have a wide range of", x, y += (ylinesize*2));
        doc.text("experiences in life. ", x, y += ylinesize);
        doc.text("Change, travel, and adventure all resonate with the 5 Path. ", x, y += (ylinesize*2));
        doc.text("Business and commerce can be fulfilling as well.", x, y += (ylinesize*2));
        doc.text("These individuals often find fulfillment in some type of", x, y += (ylinesize*2));
        doc.text("teaching role, where they can bring their wealth of", x, y += ylinesize);
        doc.text("experience and knowledge to others.", x, y += ylinesize);
      }
      else if (a_path == 6) {
        doc.text("6: ARCLINE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Path of 6 is one of dedication to family, being of service,", x, y += (ylinesize*2));
        doc.text("and making the world a warmer and more caring place.", x, y += ylinesize);
        doc.text("This can manifest in many ways. The 6 Path can lead someone", x, y += (ylinesize*2));
        doc.text("to be a counselor, or to do work in a school or hospital.", x, y += ylinesize);
        doc.text("The 6 Path has a lot to do with improving the condition of", x, y += (ylinesize*2));
        doc.text("humanity. Whether local or worldwide, this 6 will want to", x, y += ylinesize);
        doc.text("make a difference in the quality of people's lives.", x, y += ylinesize);
      }
      else if (a_path == 7) {
        doc.text("7: AURA", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A 7 Path will draw a person toward a life in which they", x, y += (ylinesize*2));
        doc.text("can use their mind and great spirit to elevate or educate", x, y += ylinesize);
        doc.text("others.", x, y += ylinesize);
        doc.text("It might be a professor in a university, a religious or", x, y += (ylinesize*2));
        doc.text("inspirational leader, or Red Cross volunteer.", x, y += ylinesize);
        doc.text("The Path of 7 can also lead a person away from the world,", x, y += (ylinesize*2));
        doc.text("into an environment in which they can work in relative", x, y += ylinesize);
        doc.text("seclusion or isolation. ", x, y += ylinesize);
        doc.text("The scientist in the laboratory and the park ranger in the", x, y += (ylinesize*2));
        doc.text("forest are also typical 7 paths.", x, y += ylinesize);
      }
      else if (a_path == 8) {
        doc.text("8: PRANIC BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("The Path of 8 can take on many forms. Business is a very", x, y += (ylinesize*2));
        doc.text("likely arena for the 8 Path, but the healing arts and", x, y += ylinesize);
        doc.text("medicine can have just as strong a pull on their hearts.", x, y += ylinesize);
        doc.text("Whether a corporate executive or someone caring for animals", x, y += (ylinesize*2));
        doc.text("in a shelter, a person on the 8 Path will be led to ", x, y += ylinesize);
        doc.text("experiences and events that will help develop a sense of", x, y += ylinesize);
        doc.text("personal power, wealth, and health.", x, y += ylinesize);
      }
      else if (a_path == 9) {
        doc.text("9: SUBTLE BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Path of 9 is one of self-mastery. This can take many forms,", x, y += (ylinesize*2));
        doc.text("even as a way of being or personal philosophy.", x, y += ylinesize);
        doc.text("It might be someone who has mastered the attitude of", x, y += (ylinesize*2));
        doc.text("gratitude in their life, or it can take on more dramatic", x, y += ylinesize);
        doc.text("tones, as someone who has studied and mastered seven", x, y += ylinesize);
        doc.text("languages, or a concert soprano who sings in the opera.", x, y += ylinesize);
        doc.text("Whether humble or grand, these 9s will gain fulfillment in", x, y += (ylinesize*2));
        doc.text("life through their sensitive expression and honoring their", x, y += ylinesize);
        doc.text("true passion.", x, y += ylinesize);
      }
      else if (a_path == 10) {
        doc.text("10: RADIANT BODY", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Path of 10 finds fulfillment in leadership and ", x, y += (ylinesize*2));
        doc.text("accomplishment. ", x, y += ylinesize);
        doc.text("Whether the chairman of the board or a missionary in the ", x, y += (ylinesize*2));
        doc.text("Peace Corps, the 10 Path will like to lead the way and ", x, y += ylinesize);
        doc.text("receive the rewards for its brilliance. Degrees, awards,", x, y += ylinesize);
        doc.text("and certificates are all appreciated by this 10. ", x, y += ylinesize);
        doc.text("Their personal flair and inspirational ability can take", x, y += (ylinesize*2));
        doc.text("them far in life, and they will find the greatest", x, y += ylinesize);
        doc.text("fulfillment when they allow themselves to reach for the", x, y += ylinesize);
        doc.text("stars.", x, y += ylinesize);
      }
      else if (a_path == 11) {
        doc.text("11: LIGHTHOUSE", x, y);
        doc.setFontSize(12);

        this.setTextToLightYellow(doc);
        doc.setCharSpace(0.5);
        
        doc.text("A Path of 11 can take many forms, since 11 is a Master", x, y += (ylinesize*2));
        doc.text("number, which embodies the positive characteristics of all", x, y += ylinesize);
        doc.text("the numbers.", x, y += ylinesize);
        doc.text("However, if the themes of spiritual elevation and vision", x, y += (ylinesize*2));
        doc.text("are prevalent, it allows the highest destiny to be reached.", x, y += ylinesize);
        doc.text("Whether they are doing research to cure a disease or working", x, y += (ylinesize*2));
        doc.text("with disadvantaged inner city youth, this 11 will want to", x, y += ylinesize);
        doc.text("shine the light of hope and inspiration to all.", x, y += ylinesize);
      }
    },

    sendEmail(docBase64) {
      try {
        let birthdate_str = this.day.toString() + " - " + this.month.toString() + " - " + this.year.toString(); 

        var axiosParams = {
          name: this.name,
          email: this.email,
          birthdate: birthdate_str,
          attachment: docBase64,
        };

        axios.post(
            "src/mail.php",
            axiosParams
          )
          .then(response => {
            console.log("e-mail sent", response);
            this.$emit('emailsent');
            this.emailIsSent();
          })
          .catch(error => {
            console.error("There was an error!", error);
          });
      } catch(error) {
          console.log({error})
      }
    },

    drawLink(doc, a_x, a_y, text, a_url, a_underlined, a_widthMultiplier) {
        let textWidth = doc.getTextWidth(text);
        doc.setDrawColor(19, 38, 82);
        doc.setTextColor(19, 38, 82);
        doc.textWithLink(text, a_x, a_y, {url: a_url});  
        if (a_underlined) {
          doc.line(a_x, a_y+1, a_x + (textWidth * a_widthMultiplier), a_y+1);
        }      

        return doc;
    },

    numerologyAddNumbers(str) {
        let parsedNum = 0;

        for (let i = 0; i < str.length; i++) {
          parsedNum +=  +str.charAt(i);
        }

        return parsedNum;
    },

    numerologyStringToNumber(str) {
      let num = +str; 
      let parsedNum = 0;

      if (num <= 11) {
        parsedNum = num;
      }
      else {
        parsedNum = this.numerologyAddNumbers(str);
      }

      while (parsedNum > 11){
        parsedNum = this.numerologyAddNumbers(parsedNum.toString());
      }

      return parsedNum;
    },

    monthNrToString(month) {
           if (month ==  1) { return "January"; }
      else if (month ==  2) { return "February"; }
      else if (month ==  3) { return "March"; }
      else if (month ==  4) { return "April"; }
      else if (month ==  5) { return "May"; }
      else if (month ==  6) { return "June"; }
      else if (month ==  7) { return "July"; }
      else if (month ==  8) { return "August"; }
      else if (month ==  9) { return "September"; }
      else if (month == 10) { return "October"; }
      else if (month == 11) { return "November"; }
      else if (month == 12) { return "December"; }
    },

    dayNrToString(day) {
           if (day ==  1  || day == 21  || day == 31) { return  +day + "st"; }
      else if (day ==  2  || day == 22) { return  +day + "nd"; }
      else {
        return +day + "th";
      }
    },

    getDos(soul, mastery, gift) {
      var soul_ = [];
      soul_.push(   ["LET ME TRY NEW THINGS, BECAUSE"                  , "I LIKE TO PIONEER."]); // <- 1
      soul_.push(   ["CONNECT TO ME, I LOVE TO HAVE COMPANY"           , "AROUND ME AND FEEL INCLUDED."]);
      soul_.push(   ["LOVE ME FOR MY NEVER ENDINGG STREAM OF"          , "IDEAS ME KNOW YOU NEED ME."]);
      soul_.push(   ["APPROACH ME WITH A PLAN AND ALLOW ME"            , "TO WORK STEP BY STEP ON IT. "]);
      soul_.push(   ["GIVE ME THE FREEDOM TO EXPRESS MYSELF"           , "AND EXPLORE NEW PEOPLE, PLACES AND IDEAS."]);
      soul_.push(   ["SHARE PROJECTS AND IDEA'S WHICH ARE OF"          , "BENEFIT TO THOSE IN NEED."]);
      soul_.push(   ["HELP ME TO CONNECT TO MY FEELINGS"               , "AS MY MIND PROCESSES EVERYTHING FIRST."]);
      soul_.push(   ["SLOW ME DOWN WHEN YOU SEE ME GETTING"            , "AHEAD OF MY SELF IN MY DRIVE FOR SUCCESS."]);
      soul_.push(   ["BE GENTLE, CLEAR AND PRECISE IN WHAT YOU"        , "WANT FROM ME."]);
      soul_.push(   ["REMIND ME 'Not too much, not too little' TO"     , "HELP ME OVERCOME MY 'All or Nothing' ATTITUDE'."]);
      soul_.push(   ["INVITE ME TO SING HOLY SONGS"                    , "I LOVE THEM."]);

      var mastery_ = [];
      mastery_.push(["REMIND ME OFF THE IMPORTANCE OF INCLUSION,"      , "I SOMETIMES FORGET."]); // <- 1
      mastery_.push(["KNOW THAT IT CAN BE CHALLENGING FOR ME TO"       , "SET BOUNDARIES."]);
      mastery_.push(["LET ME TAKE CARE OF YOU AND LET ME KNOW"         , "YOU NEED ME."]);
      mastery_.push(["HELP ME SEE THE BIGGER PICTURE"                  , "I CAN GET STUCK IN THE DETAILS."]);
      mastery_.push(["TAKE ME TO THE GYM, MY BODY NEEDS TO SWEAT"      , "EVERY DAY."]);
      mastery_.push(["CREATE A SAFE ENVIRONMENT FOR ME, THEN"          , "I CAN OPEN UP MORE."]);
      mastery_.push(["TAKE ME FOR A WALK IN NATURE,"                   , "WHEN YOU SEE ME STRUGGLING."]);
      mastery_.push(["KNOW THAT MONEY AND POWER ARE IMPORTANT"         , " TO ME."]);
      mastery_.push(["I KNOW I CAN READ BETWEEN THE LINES, I"          , "TEND TO INTUITIVELY KNOW WHAT`S GOING ON."]);
      mastery_.push(["GIVE ME THE OPPORTUNITY TO LEAD."                , " "]);
      mastery_.push(["REMIND ME TO TAKE A BREAK, DRINK SOME WATER"     , "OR TAKE A WALK WHEN I`M AT WORK."]);

      var gift_ = [];
      gift_.push(   ["ASK ME IF YOU`RE IN A CONFLICT."                 , "I`M USUALLY ABLE TO TURN IT INTO A WIN."]); // <- 0
      gift_.push(   ["KNOW THAT BEING INDEPENDENT IS VERY"             , "IMPORTANT TO ME."]); // <- 1
      gift_.push(   ["LET ME BE PART OF YOUR PROJECT, I WILL BRING"    , "PEACE AND HARMONY TO IT."]);
      gift_.push(   ["HELP ME SET REALISTIC GOALS, BECAUSE I TEND"     , "TO LOOK ONLY AT THE POSITIVE SIDE."]);
      gift_.push(   ["ASK ME FOR ADVICE OR SUPPORT,"                   , "I AM HERE FOR YOU."]);
      gift_.push(   ["BECOME MY ACCOUNTABILITY PARTNER TO HELP ME"     , "GET THINGS DONE."]);
      gift_.push(   ["BE HONEST WITH ME, JUSTICE"                      , "IS VERY IMPORTANT TO ME."]);
      gift_.push(   ["RESPECT MY ME-TIME,"                             , "I NEED IT TO RECHARGE MYSELF."]);
      gift_.push(   ["LET ME LEAD A TEAM OR A COMPANY,"                , "I LOVE TO EMPOWER OTHERS."]);
      gift_.push(   ["I AM CAPABLE OF KEEPING TRACK OF THE DETAILS"    , "IN RELATION TO THE BIGGER PICTURE."]);
      gift_.push(   ["DON`T PUSH ME TOO FAR, OR YOU`LL"                , "GET ANOTHER FIGHT."]);
      gift_.push(   ["FORGIVE ME IF I AM OVER-CONTROLLING"             , "OR FANATIC."]);

      var ret = [soul_[soul-1], mastery_[mastery-1], gift_[gift]];
      return ret;
    },

    getDonts(soul, mastery, gift) {
      var soul_ = [];
      soul_.push(   ["BLAME ME FOR NOT TAKING INTO ACCOUNT"        , "YOUR FEELINGS AND NEEDS."]); // <- 1
      soul_.push(   ["TAKE MY SUPPORT FOR GRANTED."                , ""]);
      soul_.push(   ["PUT ME IN SITUATIONS WHERE THERE IS NO ROOM" , "FOR MY CREATIVITY."]);
      soul_.push(   ["PROMISE ME MORE THAN YOU CAN DELIVER."       , ""]);
      soul_.push(   ["TAKE MY PERSONAL FREEDOM FROM ME."           , ""]);
      soul_.push(   ["BE DISHONEST OR LIE TO ME. I WILL REMEMBER." , ""]);
      soul_.push(   ["ASK ME TO MAKE DECISIONS WITHOUT INFORMATION", "OR WITHOUT KNOWING WHY."]);
      soul_.push(   ["MAKE ME FEEL THAT MY PERSONAL POWER IS"      , "IS COMPROMISED IN ANY WAY."]);
      soul_.push(   ["ARE RUDE IN YOUR COMMUNICATION. IT"          , "WILL GIVE YOU THE OPPOSITE FROM WHAT YOU WANT..."]);
      soul_.push(   ["ALLOW ME TO NEGLECT MY FAMILY OR MY OWN"     , "HEALTH OVER MY CAREER."]);
      soul_.push(   ["DISCRIMINATE, IT BREAKS MY HEART."           , ""]);

      var mastery_ = [];
      mastery_.push(["EXPECT ME TO FOLLOW YOU COMPLETELY"          , "I LIKE TO DO IT MY WAY."]); // <- 1
      mastery_.push(["EXCLUDE ME FROM THE TEAM."                   , ""]);
      mastery_.push(["TELL ME SOMETHING IS NOT POSSIBLE, AND I`LL" , "SHOW YOU THERE IS A WAY."]);
      mastery_.push(["EXPECTING ME TO DO THINGS THAT ARE HARMFUL"  , "TO OTHERS OR THE PLANET."]);
      mastery_.push(["TRY AS I MIGHT TO PUT ME OFF, I HAVE A"      , "GOOD SENSE OF THINGS."]);
      mastery_.push(["PLACE YOUR HIGH EXPECTATIONS ON ME."         , ""]);
      mastery_.push(["BREAK MY CONFIDENCE, BECAUSE IT WILL TAKE A" , "LONG TIME TO REBUILD."]);
      mastery_.push(["LET ME BE LAZY, I NEED TO PRACTICE"          , "OTHERWISE FEARS WILL COME."]);
      mastery_.push(["OVERWHELM ME WITH YOUR PRESENCE, I'M A "     , "VERY SENSITIVE BEING AND NEED MY SPACE."]);
      mastery_.push(["IF YOU GET ON MY NERVES I CAN`T STAND IT"    , "VERY WELL."]);
      mastery_.push(["QUESTION MY INTEGRITY, I WILL"               , "NOT FORGET IT."]);

      var gift_ = [];
      gift_.push(   ["DONT`T PUSH ME TO JUDGE OTHERS.", " "]); // <- 0
      gift_.push(   ["FORGET ABOUT ASKING MY OPINION, BECAUSE EVEN", "THOUGH WE DISAGREE I RESPECT YOU."]); // <- 1
      gift_.push(   ["ASK ME TO MAKE DECISIONS WHEN I`M NOT"       , "FEELING WELL."]);
      gift_.push(   ["CONDEMN ME FOR MY INSECURITY."               , ""]);
      gift_.push(   ["TAKE MY WORDS FOR GRANTED, IT HAS POWER."    , ""]);
      gift_.push(   ["TELLING ME THAT EVERYTHING WILL GET BETTER"  , "IF I 'JUST' FOLLOW A DAILY ROUTINE."]);
      gift_.push(   ["QUARRELING WITHIN THE FAMILY, IT HURTS ME TO", "THE SOUL."]);
      gift_.push(   ["TAKE AWAY MY ME-TIME. I NEED IT TO RECHARGE.", ""]);
      gift_.push(   ["AVOID ME OR PRETEND NOTHING HAPPENED WHILE"  , "YOU KNOW I`M GRIEVING."]);
      gift_.push(   ["LET ME DOUBT MY INTUITION, IT IS STRONG AND" , "I CAN TRUST IT."]);
      gift_.push(   ["PLAY GAMES WITH ME, I DON`T LIKE TO LOSE."   , ""]);
      gift_.push(   ["TRY TO FORCE YOUR WAY TO ME."                , ""]);

      var ret = [soul_[soul-1], mastery_[mastery-1], gift_[gift]];
      return ret;
    },

    loadImageAndCreatePDF() {
        console.log("getReadingButtonDisabled: ");
        console.log(this.getReadingButtonDisabled);

        if (this.getReadingButtonDisabled) {
          return;
        }

        this.getReadingButtonDisabled = true;
        this.getReadingButtonTimeout = setTimeout(() => {
          this.getReadingButtonDisabled = false
        }, 2500); // <- Don't enable for now.
        
        let name    = `${this.name}`;
        let b_day   = `${this.day}`;
        let b_month = `${this.month}`;
        let b_year  = `${this.year}`;

        if (name == '' || b_day == '' || b_month == '' || b_year == '') {
          console.log("Cannot make PDF: Not all details are filled in.")
          return;
        }

        this.loadCroppedImage();
    },

    createPDF() {
        let name    = `${this.name}`;
        let b_day   = `${this.day}`;
        let b_month = `${this.month}`;
        let b_year  = `${this.year}`;
        let email   = `${this.email}`;

        if (email) {
          this.getReadingButtonText = "Composing Email...";
        }

        // Calculate the numbers:
        let n_soul        = this.numerologyStringToNumber(b_day);
        let n_mastery     = this.numerologyStringToNumber(b_month);
        let n_gift        = this.numerologyStringToNumber(b_year.substring(2));
        let n_knowledge   = this.numerologyStringToNumber(b_year);
        let n_path        = this.numerologyStringToNumber(b_day + b_month + b_year);

        let doc = new jsPDF({compress: true});

        let logo = new Image();
        logo.src = 'src/assets/images/logo.png';
        let transparentlogo = new Image();
        transparentlogo.src = 'src/assets/images/logo_transparent.png';

        // Page 1:
        console.log("draw block dark blue");
        this.drawBlockDarkBlue(doc, 0, 210, 210, 87);

        let profilepicture = null;
        if (this.croppedImageLoaded) {
          profilepicture = this.croppedImage;
        }
        else {
          profilepicture = new Image();
          profilepicture.src = 'src/assets/images/genericprofilepicture.png';
        }

        doc.addImage(profilepicture, 'png', 0, 0, 210, 210, undefined, 'MEDIUM');        
      
        doc.setCharSpace(3);
        doc.setFontSize(120);
        this.setTextToDarkBlue(doc);
        doc.text("BLUE PR", 8, 5, undefined, 270);
        this.setTextToYellowOrange(doc);
        doc.text("INT", 8, 210, undefined, 270);
        
        doc.setFontSize(19);
        doc.setCharSpace(1);
        this.setTextToGrey(doc);
        doc.text("ANALYSIS", 8, 290);

        doc.setFontSize(25);
        let x = 45;
        let y = 210;
        let ylinesize = 12.5;
        doc.text("PRACTICAL GUIDE TO BE THE", x, y += ylinesize);
        doc.text("BEST VERSION OF YOURSELF ", x, y += ylinesize);

        doc.setFontSize(15);
        doc.setFont(doc.getFont().fontName, 'bold');
        y += 1;
        ylinesize = 10;
        doc.text("For " + name + " who", x, y += ylinesize);
        doc.text("said 'Hi!' to earth on", x, y += ylinesize);
        doc.text(this.dayNrToString(+b_day) + " " + this.monthNrToString(+b_month) + " " + b_year, x, y += ylinesize);

        doc.addImage(transparentlogo, 'png', 140, 247, 87.5, 50, undefined, 'MEDIUM');

        // Page 2:
        doc.addPage();

        let page2_image = new Image();
        page2_image.src = 'src/assets/images/Pagina2.png';
        doc.addImage(page2_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        // Page 3:
        doc.addPage();

        let page3_image = new Image();
        page3_image.src = 'src/assets/images/Pagina3.png';
        doc.addImage(page3_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        // Page 4:
        doc.addPage();

        let page4_image = new Image();
        page4_image.src = 'src/assets/images/Pagina4.png';
        doc.addImage(page4_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        // Page 5:
        doc.addPage();

        let page5_image = new Image();
        page5_image.src = 'src/assets/images/Pagina5.png';
        doc.addImage(page5_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');
        
        doc.addImage(profilepicture, 'png', 19, 36, 172, 172, undefined, 'MEDIUM');

        let fullreport_url = `${this.fullreport_url}`;
        doc.setFontSize(10);
        this.drawLink(doc, 100, 269 , "YES I"    , fullreport_url, false, 1.4);
        this.drawLink(doc,  95, 274 , "WANT MY"  , fullreport_url, false, 1.325);
        this.drawLink(doc, 100, 279 , "FULL"     , fullreport_url, false, 1.275);
        this.drawLink(doc, 97.5, 284, "REPORT"   , fullreport_url, false, 1.275);

        // Page 6:
        doc.addPage();

        let page6_image = new Image();
        page6_image.src = 'src/assets/images/Pagina6.png';
        doc.addImage(page6_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM'); 

        this.setTextToLightYellow(doc);
        doc.setFontSize(90);
        doc.text(n_soul.toString(),      42.5, 157.5);
        doc.text(n_gift.toString(),       115, 160);
        doc.text(n_path.toString(),       170, 195);
        doc.text(n_mastery.toString(),     45, 227);
        doc.text(n_knowledge.toString(),  117.5, 227);

        // Page 7:
        doc.addPage();

        let page7_image = new Image();
        page7_image.src = 'src/assets/images/Pagina7.png';
        doc.addImage(page7_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM'); 

        this.drawPage7(doc, n_soul);
        
        // Page 8:
        doc.addPage();
        
        let page8_image = new Image();
        page8_image.src = 'src/assets/images/Pagina8.png';
        doc.addImage(page8_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        this.drawPage8(doc, n_mastery);

        // Page 9:
        doc.addPage();
        
        let page9_image = new Image();
        page9_image.src = 'src/assets/images/Pagina9.png';
        doc.addImage(page9_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        this.drawPage9(doc, n_gift);

        // Page 10:
        doc.addPage();
        
        let page10_image = new Image();
        page10_image.src = 'src/assets/images/Pagina10.png';
        doc.addImage(page10_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        this.drawPage10(doc, n_knowledge);

        // Page 11:
        doc.addPage();
        
        let page11_image = new Image();
        page11_image.src = 'src/assets/images/Pagina11.png';
        doc.addImage(page11_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        this.drawPage11(doc, n_path);

        // Page 12:
        doc.addPage();

        let page12_image = new Image();
        page12_image.src = 'src/assets/images/Pagina12.png';
        doc.addImage(page12_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');        

        var dos   =   this.getDos(n_soul, n_mastery, n_gift);
        var donts = this.getDonts(n_soul, n_mastery, n_gift);

        y = 94;
        ylinesize = 22.5;
        x = 40;
        doc.setFontSize(15);
        doc.text(  dos[0][0], x, y);
        doc.text(  dos[0][1], x, y + 8);

        doc.text(  dos[1][0], x, y += ylinesize);
        doc.text(  dos[1][1], x, y + 8);

        doc.text(  dos[2][0], x, y += ylinesize);
        doc.text(  dos[2][1], x, y + 8);

        ylinesize = 21;
        doc.text(donts[0][0], x, y += 53);
        doc.text(donts[0][1], x, y + 8);

        doc.text(donts[1][0], x, y += ylinesize);
        doc.text(donts[1][1], x, y + 8);

        doc.text(donts[2][0], x, y += ylinesize);
        doc.text(donts[2][1], x, y + 8);

        // Page 13:
        doc.addPage();

        let page13_image = new Image();
        page13_image.src = 'src/assets/images/Pagina13.png';
        doc.addImage(page13_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');

        doc.setFontSize(26);
        this.drawLink(doc,  93, 166, "YES I"    , fullreport_url, false, 1.4);
        this.drawLink(doc,  83, 178, "WANT MY"  , fullreport_url, false, 1.325);
        this.drawLink(doc,  93, 190, "FULL"     , fullreport_url, false, 1.275);
        this.drawLink(doc,  86, 202, "REPORT"   , fullreport_url, false, 1.275);

        // Page 14:
        doc.addPage();

        let page14_image = new Image();
        page14_image.src = 'src/assets/images/Pagina14.png';
        doc.addImage(page14_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');

        doc.setFontSize(17);
        this.drawLink(doc,  96, 160, "YES I"    , fullreport_url, false, 1.4);
        this.drawLink(doc,  90, 168, "WANT MY"  , fullreport_url, false, 1.325);
        this.drawLink(doc,  97, 176, "FULL"     , fullreport_url, false, 1.275);
        this.drawLink(doc,  92, 184, "REPORT"   , fullreport_url, false, 1.275);

        // Page 15:
        doc.addPage();

        let page15_image = new Image();
        page15_image.src = 'src/assets/images/Pagina15.png';
        doc.addImage(page15_image, 'png', 0, 0, 210, 297, undefined, 'MEDIUM');
        //// End of pages

        this.document = doc;

        this.cropperVisible = 'hidden';

        if (email) {
          var pdfBase64 = doc.output('datauristring');
          console.log("pdfBase64: ");
          console.log(pdfBase64);
          this.sendEmail(pdfBase64);
          //
          this.responseAvailable = false;

          /*// tmp:
          var api_key = "yGdrrBdgTVjcwPEhOopD2LajEztb9eMqDGVVO3y9Rw5a6qeY/L3goaVI";
          let api_secret = this.$CryptoJS.enc.Base64.parse("Fc59lr4PvArflVZk/aQ1bsHAvOYykQD3BdAgT58D0opnuKZdMe4SyMdBR0KGBHsppGrytbrmacpfrCAFJ5KUnQ==");
          var api_nonce = (Date.now() * 1000).toString();
          let api_endpoint = "/0/private/Balance";
          let api_post = "nonce=" + api_nonce;
          
          let api_sha256 = this.$CryptoJS.SHA256(api_nonce + api_post);
          var api_sign = this.$CryptoJS.algo.HMAC.create(this.$CryptoJS.algo.SHA512, api_secret);
          api_sign.update(api_endpoint, api_secret);
          api_sign.update(api_sha256, api_secret);
          api_sign = api_sign.finalize().toString(this.$CryptoJS.enc.Base64);

          fetch("https://api.kraken.com/0/private/Balance", {
            "method": "POST",
            "headers": {
              'API-Key': api_key, 
              'API-Sign': api_sign, 
              'Content-Type': 'application/x-www-form-urlencoded',
//              'Access-Control-Allow-Headers': 'Origin, Accept, Content-Type, Content-Length, Access-Control-Allow-Methods, Access-Control-Allow-Origin, Access-Control-Expose-Headers, Access-Control-Max-Age, Allow, Authorization',
//              'Origin': 'https://connect.mailerlite.com/api/subscribers/',
              'Access-Control-Allow-Headers': '*',
              'Accept': 'application/x-www-form-urlencoded', 
              'Access-Control-Allow-Methods': 'PUT, GET, HEAD, POST, DELETE, OPTIONS',
              'Access-Control-Allow-Origin': '*'
          },
            "body": JSON.stringify( { nonce: api_nonce} )
          }).then(response => {
            if (response.ok) {
              console.log("response OK");
            }
            else {
              console.log(response);
              console.log("response not OK");
            }
            this.responseAvailable = true;
          }).catch(err => {
            console.log(err);
          });

          // end tmp*/
          
          var first_name, last_name;
          
          if (name.includes(" ")){
            var names = name.split(" ").filter(Boolean);
            first_name = names[0];
            last_name = name.substring(first_name.length).trim();
            console.log(first_name + " " + last_name);
          }
          else {
            first_name = name;
          }

          fetch("https://connect.mailerlite.com/api/subscribers/", {
            "method": "POST",
            "headers": {
              'Access-Control-Allow-Headers': '*',
              'Accept': 'application/json', 
              'Content-Type': 'application/json', 
              'Access-Control-Allow-Methods': 'PUT, GET, HEAD, POST, DELETE, OPTIONS',
              'Access-Control-Allow-Origin': '*',
              'Authorization': 'Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJhdWQiOiI0IiwianRpIjoiNjliMGIyNmM1ZTA3ODNiYzNjNjMxMzE1Y2U4NDZiNWM0OWZjNjdmMzM3YjcxYzE0NGU5YWI1YTNiODAyNmUyNjJiNmM4NDMwMzgxZmEwNjAiLCJpYXQiOjE3MTkzMTUxODMuMzk5OTc0LCJuYmYiOjE3MTkzMTUxODMuMzk5OTc3LCJleHAiOjQ4NzQ5ODg3ODMuMzk3MzU2LCJzdWIiOiIxMTAwMDMiLCJzY29wZXMiOltdfQ.m_iMdYyKDPRJzhk968rIU37C1jDxDW7Eu29A1B8m9WIxbjD1CfORX-hj9x1Dy_ohP5oXcpPLZwIeW-b_TYYKp3BxArtc4r0jl5SN5m5kh5zuK6pbJnHfdRb951jK4_y8hFY4HRdrMxbqJRwk-ArLlu67rpAoO9AKBWiDGqYNfvdnMeX4yBiawl9yrJAk6j8XFg6vSXEqjDIL3XyAwkmnIGXFT3Gi9Uy6nwpXf8YM7H8QE2gWjD4vqVVCEaiIp4mHeTFzpYzc8MqWpNLv10z2KGD0nHaGHaaxBDuLIX0gWFBlY_3CvMCGH-_rm0h91N3BqJPWGCNvItFyldi13apfnh4_gvbcYi_OQBm9RjUxDjtn23aAHfHApOVbTrDVcoiO_4B6tJFXh1yuynXIXhjWLVsGrDgYT6Q5TDrvgy3JW6tiqehio8zRD97pmj1QtfnQWy3bbLyDE4yZj3fYApJoFuMssVf4bcNIEkkM36QxXp9bRRpw8Ix-HHoocB0Y7sviYgju29ourl8NvCDENCT1lVT7waoHCbH6LfPe9g4g_3t_DdHxIAtq5kY7WKyTkXfpoXIqg5C3r0cOv7FO_0KDOLWm901JnXSsnIDqHs8PCpcenR4Or83H_PIYGZuV4HxOZTJdxL5YCC7crwjsZNYu-zxmCxM1G0sEYmMsTtHs4AA'
            },
            "body": JSON.stringify( { email: email, fields: { name: first_name, last_name: last_name}, groups: ["82900259752117350"]} )
          }).then(response => {
            if (response.ok) {
              console.log("response OK");
            }
            else {
              console.log(response);
              console.log("response not OK");
            }
            this.responseAvailable = true;
          }).catch(err => {
            console.log(err);
          });
          //
        }
        else {
          this.emailIsSent();
        }

//        doc.save(pdfName + '.pdf', true);

//        var fs = require('fs');
//        try { fs.writeFileSync("BluePrintReport.pdf", doc.getBlob(), 'utf-8'); }
//        catch(e) { alert('Failed to save the file !'); }
    },

    emailIsSent() {
      this.getReadingButtonText = "Done, check your email";
      this.onGetReadingLeft = '20px';

      let name     = `${this.name}`;
      let b_day    = `${this.day}`;
      let b_month  = `${this.month}`;
      let b_year   = `${this.year}`;
      let b_date   = this.dayNrToString(+b_day) + " " + this.monthNrToString(+b_month) + " " + b_year;
      var filename = 'Akara BluePrint for ' + name + ' ' + b_date + '.pdf';
      this.document.save(filename);
    },
  },
  
  components: {
    Cropper,
    //"ejs-dialog": DialogPlugin,
  }
}

</script>

<template>
  <ChildComponent @emailsent="emailIsSent" />
  <div class="header" style="font-size:0;">
    <div class="onlyOnMobile">
      <p style="font-size: 10px; background-image: url('src/assets/images/Headergeheel.png'); background-repeat:no-repeat;" class="HeaderMobile">
        <br><br><br><br><br>
        <br><br><br><br><br>
        <br><br><br><br><br>
        <br><br><br><br><br>
        <br><br><br><br><br><br>
      </p>
    </div>
    
    <div class="onlyOnDesktop">
      <img :src="headerImage" style="width:100%;" alt="">
      <img :src="underHeaderLine" style="width:100%;" alt="">
    </div>
  </div>

  <div class="underheader">
    <img :src="greyPart" alt="" class="greypart">
    <div class="blueprintanalysis">

      <p style="font-family: Style Script; font-size: 36px;">Blueprint Analysis</p>

      <div class="onlyOnDesktop">
        <p style="text-align: left; width: inherit;">
          You're about to apply for your personal <b>BluePrint Analysis</b>.<br><br>
          In this <b>report</b> you'll find your <b>5 basic numbers</b>, calculated according to the <b>Akara Numerology</b> <br>
          and 3 <b>do's & donts</b> to share with others to meet the most powerful version of you. <br><br>
          <b>Akara Numerology</b> is a <b>technology</b> that allows us to <b>better understand ourselves</b> and the <br>
          <b>experience of our lives.</b>
        </p>
      </div>
      
      <div class="onlyOnMobile">
        <p style="text-align: left">
          You're about to apply for your personal <b>BluePrint Analysis</b>.<br><br>
          In this <b>report</b> you'll find your <b>5 basic numbers</b>, calculated according to the <b>Akara Numerology</b>
          and 3 <b>do's & donts</b> to share with others to meet the most powerful version of you. <br><br>
          <b>Akara Numerology</b> is a <b>technology</b> that allows us to <b>better understand ourselves</b> and the
          <b>experience of our lives.</b>
        </p>
      </div>
    </div>

    <div class="onlyOnDesktop">
      <br><br><br>
    </div>
    
    <div class="onlyOnMobile">
      <br>
    </div>
    <img :src="previewBlueprint" alt="" class="previewBlueprint">
<!--    <img :src="underHeaderLeft" alt="" class="underheaderleft">-->
    <div class="onlyOnMobile">
    </div>
    <div class="bluepart">
      <img :src="inputBlue" alt="" class="blueinput">
      <div class="onblue">
        <div class="onbluetext">
          <h1>Get your free BluePrint Analysis</h1>
          <h3>Fill in your details, select a photo, and get your own BluePrint Report.</h3>
          <br>
          <div class="onwhite">
            <input
              v-model="day"
              class="FormDate__input FormDate__input--day"
              type="number"
              placeholder="dd">
              
              <input
              v-model="month"
              class="FormDate__input FormDate__input--month"
              type="number"
              placeholder="mm">
            
            <input
              v-model="year"
              class="FormDate__input FormDate__input--year"
              type="number"
              placeholder="yyyy">
          </div>
          
          <div class="onwhite">
            <input
              v-model="name"
              class="FormDate__input FormDate__input--name"
              type="text"
              placeholder="name"
              style="width: 96%; line-height: 30px;">
          </div>
          
          <br>

          <div class="uploadphoto">
            <img :src="buttonYellow" alt="">
            <div class="onuploadphoto"><input type="file" accept="image/jpeg" @change=uploadImage style="width:180px;"></div>
          </div>
          
          <br>
          
          <div class="onwhite">
            <input
            v-model="email"
            class="FormDate__input FormDate__input--email"
            type="text"
            placeholder="e-mail"
            style="width: 96%; line-height: 30px;">
          </div>
          
          <br>
          <div style="display: flex; align-items: center; justify-content: center">
            <div class="lockedIconImg">
              <img :src="lockedIcon" alt="" width='25'>          
            </div>
            <div class="yourInformationText">
               Your Information is 100% Secure And Will Never Be Shared With Anyone.
            </div>
          </div>
          <br>
          <br>
  
          <div class="getreadingbutton">
            <img :src="buttonYellow" alt="" class="getreading" @click="loadImageAndCreatePDF">
            <div class="ongetreading" :disabled="getReadingButtonDisabled" @click="loadImageAndCreatePDF" tabindex="0">
              {{ getReadingButtonText }}
            </div>
          </div>
        </div>

      </div>
    </div> 
  </div>

  <div>
    <cropper
      ref="cropper"
      class="cropper"
      :src="previewImage"
      :stencil-props="{
        aspectRatio: 1/1
      }"
    ></cropper>
  </div>

  <div class="onlyOnDesktop">
    <br><br><br><br>
    <br><br>
  </div>
  
  <br>
  
  <div class="getfullreport">
    Get Your Full report
    <br><br>

    <div style="font-size:20px;"><b>
      I'm ready to find out more and want to order the full report to receive my personal affirmation and personal lifestyle suggestions, including a 30-minute personal consultation with Sat Kirtan. 
    </b>
    <br><br><br>
    </div>

    <div class="orderfull">
      <a href="https://academyofakaranumerology.com/products/akara-numerology-personal-blueprint-analysis">
      <img :src="orderFullReport" alt="" class="orderfullimage">
      <div class="onorderfullimage">
      YES I WANT<br>
      TO ORDER MY<br>
      FULL REPORT<br>
      </div>
      </a>
    </div>
  </div>

  <!-- <div id="target" class="control-section">
    <ejs-dialog 
    :target="target"
    :width="width"
    :content="content">
    </ejs-dialog>
  </div> -->
</template>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import "../../node_modules/@syncfusion/ej2-vue-popups/styles/material.css";
@import url('https://fonts.googleapis.com/css?family=Style+Script&display=swap');
    
.control-section {
  height: 100%;
  min-height: 200px;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}

.orderfull a{
  color: black;
  text-decoration: none;
}

.FormDate{
  position: relative;
  left: 350px;
  z-index: 10;
}

.header {
  position: relative;
  top: 0;
  left: 0;
}

.underheader {
  position: relative;
  top: 0;
  left: 0;
}

.blueprintanalysis {
  position: absolute;
  top: 0%;
  left: v-bind(blueprintanalysisLeft);
}

.onlyOnMobile {
  display: v-bind(mobileVisible);
}

.onlyOnDesktop {
  display: v-bind(desktopVisible);
}

.blueprintanalysis .onlyOnDesktop {
  width: v-bind(blueprintanalysisTextWidth);
}

.orderfull {
  position: relative;
}

.orderfullimage {
  width: 320px;
  height: 320px;
}

.onorderfullimage {
  z-index: 7;
  position: relative;
  top: -225px;
  font-weight: bold;
  font-size: 30px;
}

.getfullreport {
  font-weight: bold;
  font-size: 42px;
  position: v-bind(fullreportPosition);
  top: v-bind(fullreportTop);
}

.greypart {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  height: v-bind(greyPartHeight);
}

.previewBlueprint {
  position: v-bind(blueprintPosition);
  left: v-bind(blueprintLeft);
  top: v-bind(blueprintTop);
  height: v-bind(blueprintHeight);
  width: v-bind(blueprintWidth);
}

.underheaderleft {
  position: absolute;
  left: v-bind(leftImageLeft);
  top: v-bind(leftImageTop);
  height: v-bind(leftImageHeight);
  width: v-bind(leftImageWidth);
}

.FormDate__input--email {
  position: relative;
  top: -6px;
}

.blueinput {
  position: v-bind(bluePosition);
  top: v-bind(blueTop);
  left: v-bind(blueLeft);
  width: v-bind(blueWidth);
  height: v-bind(blueHeight);
  z-index: 5;
}

.onblue {
  position: v-bind(bluePosition);
  top: v-bind(blueTop);
  left: v-bind(blueTextLeft);
  width: v-bind(blueTextWidth);
  z-index: 6;
}

.FormDate__input--day{
  width: v-bind(dateInputWidth);
  line-height: 30px;
}

.FormDate__input--month{
  width: v-bind(dateInputWidth);
  line-height: 30px;
}

.FormDate__input--year{
  width: v-bind(dateInputWidth);
  line-height: 30px;
}

.onbluetext {
  color: white;
  text-align: left;
  position: v-bind(onBlueTextPosition);
  top: v-bind(onBlueTextTop);
}

.whiteinput {
  z-index: 6;
}

.onwhite {
  z-index: 7;
  position: relative;
}

.uploadphoto{
  position: relative;
  left: 0px;
  top: 0px;
  text-align: left;
}
.onuploadphoto {
  color: black;
  z-index: 7;
  position: absolute;
  top: 15px;
  left: 15px;
}

.yourInformationText {
  font-size: 10px;
  vertical-align: middle; 
}

.getreadingbutton {
  position: relative;
  top: -5px;
  text-align: left;
}

.getreading{
  position: relative;
  top: 0px;
  left: 0px;
}

.ongetreading {
  color: black;
  z-index: 7;
  position: relative;
  top: -40px;
  left: v-bind(onGetReadingLeft);
  width: 160px;
  font-weight: bold;
  font-size: 14px;
}

.cropper {
  position: relative;
  width:  v-bind(cropperWidth);
  height: v-bind(cropperHeight);
  background: #DDD;
  visibility: v-bind(cropperVisible);
  top: v-bind(cropperTop);
  left: v-bind(cropperLeft);
}

.HeaderMobile {
  background-position: -850px 0px;
  background-position: v-bind(headerMobilePosition);
}
</style>
